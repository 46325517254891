import React, { useState } from "react";
import { Link } from "gatsby";
import Logo from "../../images/pskLogos/logo.png";

  function NavBar ({ data }) {
    const [isExpanded, toggleExpansion] = useState(false)
    
    return (
      <div className="border-b  bg-white py-4 px-2">
        <div className="container mx-auto">
          <nav className="flex items-center justify-between flex-wrap">
            <div className="flex items-center flex-no-shrink text-white mr-6">
              <Link
                to="/"
                className="toggleColour no-underline hover:no-underline font-bold text-2xl lg:text-4xl text-blue-600"
              >
                <img src={Logo} alt="PSK SYD" className="w-24"></img>
              </Link>
            </div>

            {/* HAMBURGER */}
            <button
              className="block md:hidden mx-auto lg:mx-0 hover:underline font-bold text-white rounded-lg mt-2 lg:mt-0 mr-0 py-4 px-6 shadow gradient justify-cend cursor-pointer"
              onClick={() => toggleExpansion(!isExpanded)}
            >
              <svg
                className="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>

            <div
              className={`${
                isExpanded ? `block` : `hidden`
              } md:block md:flex md:items-center w-full md:w-auto`}
            >
              
            {/* LINKS */}
            <div className="w-full flex-grow sm:flex md:items-center justify-end sm:w-auto">
              <div className="inline-flex">
                <ul className="flex flex-wrap flex-col p-1 md:p-2 text-sm list-reset sm:flex-row lg:flex m:justify-end md:items-center">
                  {/* MAIN LINKS */}
                  <li className="mx-4 relative mx-1 px-1 py-2 group rounded-full mb-1 md:mb-0 font-semibold whitespace-no-wrap text-gray-800 hover:text-blue-800 cursor-pointer">
                    Produkter och Tjänster
                    {/* SUB LINKS */}
                    <ul className="absolute left-0 top-0 mt-10 p-2 rounded-lg shadow-lg bg-white z-10 hidden group-hover:block">
                      {/* DROPDOWN */}
                      <svg
                        className="block fill-current text-white w-full h-4 absolute left-0 top-0 ml-3 -mt-3 z-0"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                      </svg>

                      <a href="/productsAndServices/sam">
                        <li className="p-1 whitespace-no-wrap rounded-full text-sm md:text-base text-gray-600 hover:text-gray-800 hover:bg-gray-100 cursor-pointer">
                          SAM
                        </li>
                      </a>

                      <a href="/productsAndServices/drawings">
                        <li className="p-1 whitespace-no-wrap rounded-full text-sm md:text-base text-gray-600 hover:text-gray-800 hover:bg-gray-100 cursor-pointer">
                          Ritarbeten
                        </li>
                      </a>

                      <a href="/productsAndServices/fieldwork">
                        <li className="p-1 whitespace-no-wrap rounded-full text-sm md:text-base text-gray-600 hover:text-gray-800 hover:bg-gray-100 cursor-pointer">
                          Fältarbete | 360º 
                        </li>
                      </a>

                      <a href="/productsAndServices/projectPlanning">
                        <li className="p-1 whitespace-no-wrap rounded-full text-sm md:text-base text-gray-600 hover:text-gray-800 hover:bg-gray-100 cursor-pointer">
                          Projektledning
                        </li>
                      </a>

                      <a href="/productsAndServices/errorReport">
                        <li className="p-1 whitespace-no-wrap rounded-full text-sm md:text-base text-gray-600 hover:text-gray-800 hover:bg-gray-100 cursor-pointer">
                          Felanmälan
                        </li>
                      </a>

                      <a href="/productsAndServices/authorities">
                        <li className="p-1 whitespace-no-wrap rounded-full text-sm md:text-base text-gray-600 hover:text-gray-800 hover:bg-gray-100 cursor-pointer">
                          Myndighetsärenden
                        </li>
                      </a>
                    </ul>
                  </li>

                  {/* MAIN LINKS */}
                  <li
                    className="mx-4 relative mx-1 px-1 py-2 group rounded-full mb-1 md:mb-0 font-semibold whitespace-no-wrap text-gray-800 hover:text-blue-800
                      font-semibold whitespace-no-wrap text-gray-800 hover:text-blue-800 cursor-pointer"
                  >
                    Om PSK Syd
                    {/* SUB LINKS */}
                    <ul className="absolute left-0 top-0 mt-10 p-2 rounded-lg shadow-lg bg-white z-10 hidden group-hover:block">
                      {/* DROOPDOWN */}
                      <svg
                        className="block fill-current text-white w-full h-4 absolute left-0 top-0 ml-3 -mt-3 z-0"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                      </svg>

                      <a href="/about/coworkers">
                        <li className="p-1 whitespace-no-wrap rounded-full text-sm md:text-base text-gray-600 hover:text-gray-800 hover:bg-gray-100 cursor-pointer">
                          Medarbetare
                        </li>
                      </a>

                      <a href="/about/history">
                        <li className="p-1 whitespace-no-wrap rounded-full text-sm md:text-base text-gray-600 hover:text-gray-800 hover:bg-gray-100 cursor-pointer">
                          Historia
                        </li>
                      </a>

                    </ul>           
                  </li>

                  {/* MAIN LINK */}
                  <li className="mx-4 relative mx-1 px-1 py-2 group rounded-full font-semibold whitespace-no-wrap text-gray-800 hover:text-blue-800 cursor-pointer">
                    <Link className="px-0 py-1" to="/career">
                      Karriär
                    </Link>
                  </li>
                  <li className="block md:hidden mx-4 relative mx-1 px-1 py-2 group rounded-full font-semibold whitespace-no-wrap text-gray-800 hover:text-blue-800 cursor-pointer">
                    <Link className="px-0 py-1" to="/contact">
                      Kontakt
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="hidden md:block py-3">
                <a
                  className="mx-auto lg:mx-0 hover:underline font-bold text-white rounded-full mt-4 lg:mt-0 py-4 px-8 shadow gradient justify-center cursor-pointer"
                  href="/contact"
                >
                  {" "}
                  Kontakt
                </a>
              </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
      )
};

export default NavBar;
