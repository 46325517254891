import React from "react";
import PropTypes from "prop-types";
import Footer from "./Footer.js";

function Layout({ children }) {
  return (
    <span>
      {children}
      <Footer />
    </span>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
