import React from "react";
import { StaticQuery, graphql } from "gatsby";

export default ({ render }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={render}
  />
);
